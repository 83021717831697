import Link from "next/link"
import cn from "classnames"
import { getStoryblokLink } from "utilities/getStoryblokLink"
import richText from "utilities/richText"

export default function MiniCard({ heading, content, link, link_label, background_color }) {
  return (
    <div
      className={cn(
        "relative z-10 flex flex-col justify-between rounded-br-4xl bg-springWood",
        background_color && `bg-${background_color}`,
      )}
    >
      <div className="flex h-full flex-col justify-between p-8">
        <div>
          <h2 className="pb-2.5 font-secondary text-l1 font-medium text-teal-dark lg:text-l2">{heading}</h2>
          <div>{richText(content)}</div>
        </div>
        {link && link_label ? (
          <Link
            href={getStoryblokLink(link)}
            className="block pt-5 font-secondary text-m1 font-bold text-teal-dark underline hover:no-underline"
          >
            {link_label}
          </Link>
        ) : null}
      </div>
    </div>
  )
}
